import { MapboxMapStyleId } from "../services/geometry.service";

export enum OfflineMapStatus {
  DOWNLOADED = 1,
  DOWNLOADING,
  ERROR,
  EXPIRED
}

export interface OfflineMap {
  uuid: string;
  name: string;
  status: OfflineMapStatus;
  styleId: MapboxMapStyleId;
  bounds: number[][];
  minZoom: number;
  size: number;
  preview: string;
  expires: number;
}

export type OfflineMapCreateRequest = Omit<OfflineMap, 'uuid' | 'size' | 'expires' | 'status'>;
